import React, { RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';

/**
 * ## ISearchInput
 * @param input The input JSX.Element to be processed
 * @param clickRef The ref object
 * @param showSearch Show search inputfield on mobile
 * @param onIconClick Mobile iconclick handler
 */
interface ISearchInput {
	input: JSX.Element;
	clickRef: RefObject<HTMLDivElement>;
	showSearch?: boolean;
	onIconClick?: () => void;
	onSearchIconClick?: () => void;
	className?: React.ReactNode;
	children?: React.ReactNode;
}

/**
 * ## Mobile
 * @param props see {@link ISearchInput}
 * @returns Mobile search input component suitable for Mobile viewport
 */
const Mobile: React.FC<ISearchInput> = (props) => (
	<div key={'search_mobile'} className={`searchMobile${props.showSearch ? 'Extended' : ''} ${props.className}`} ref={props.clickRef}>
		<div key={'input_wrapper_mobile'} className={'inputWrapper'}>
			<div className={'iconWrapperMobile'}>
				<div className={'iconMobile'}>
					{props.showSearch ? (
						<FontAwesomeIcon icon={faTimes} onClick={props.onIconClick} />
					) : (
						<FontAwesomeIcon icon={faSearch} onClick={props.onSearchIconClick} />
					)}
				</div>
			</div>
			{props.showSearch && (
				<div className={'iconWrapperMobile searchIconMobile'}>
					<div className={'iconMobile'}>
						<FontAwesomeIcon icon={faSearch} onClick={props.onSearchIconClick} />
					</div>
				</div>
			)}
			{props.input}
		</div>
		{props.children}
	</div>
);

/**
 * ## Desktop
 * @param props see {@link ISearchInput}
 * @returns Desktop search input component suitable for Desktop viewport
 */
const Desktop: React.FC<ISearchInput> = (props) => {
	return (
		<div key={'search'} className={`search ${props.className}`} ref={props.clickRef}>
			<div key={'input_wrapper'} className={'inputWrapper'}>
				<div className={'iconWrapper'}>
					<div className={'icon'}>
						<FontAwesomeIcon icon={faSearch} onClick={props.onSearchIconClick} />
					</div>
				</div>
				{props.input}
			</div>
			{props.children}
		</div>
	);
};

/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Format components to SearchInput.<Component>
 */
export const SearchInput = {
	Desktop: Desktop,
	Mobile: Mobile,
};
/* eslint-enable @typescript-eslint/naming-convention */
