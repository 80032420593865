
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { categories } from '../../Assets/Data';
import { IElement } from '../../Interfaces';
import { searchFilter } from '../../Utils';
import { ColorBox } from '../ColorBox';
import { Search } from '../Search';
import './styles.scss';

export const Header: React.FC = () => {
    const [getShowColors, setShowColors] = useState<boolean>();
    const [getElements, setElements] = useState<IElement[]>([]);
    const navigate = useNavigate();

    // Handle when a result is clicked
    const handleSearchResult = (key: number) => { 
        navigate(`/element/${key}`);
    };

    // When the user types in the search bar, filter for results
    const handleOnChange = (inputText: string) => {
        setElements(searchFilter(inputText));
    };

    return (
        <nav id={'header'}>
            <Search placeholder={'Search'} elements={getElements} onChange={handleOnChange} onResultClick={handleSearchResult} />
            <HeaderButton title={'Color Explanation'} onClick={() => setShowColors(!getShowColors)} />
            <ColorExplanation show={getShowColors} />
            <HeaderButton title={'Flame test'} />
        </nav>
    );
};


interface IHeaderButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    size?: number;
    onClick?(): void;
}
const HeaderButton: React.FC<IHeaderButton> = (props) => (
    <button className={`headerButton size-${props.size ?? 1}`} onClick={props.onClick}>{props.title}</button>
);


interface IColorExplanation  {
    show?: boolean
}
const ColorExplanation: React.FC<IColorExplanation> = (props) => (

    <div className={`colorExplanation${props.show ? ' show' : ''}`}>
    {categories.map((category, i) => {
        if (i !== 0) {
            /* eslint-disable react/forbid-dom-props */
            return (
                <Link to={`/category/${i}`} key={`color-${i}`}>
                    <div className={'colorContainer'}>
                        <div className={'colorBoxContainer'}>
                            <ColorBox size={'18px'} color={category.color} />
                        </div>
                        <p>{category.name}</p>
                    </div>
                </Link>
            );
            /* eslint-enable react/forbid-dom-props */
        }
    })}
    </div>
);
