
import React from 'react';
import { Link } from 'react-router-dom';
import { categories } from '../../Assets/Data';
import { IElement } from '../../Interfaces';
import './styles.scss';

/* eslint-disable react/forbid-dom-props */
export const Element: React.FC<IElement> = (props) => (
    <Link to={`/element/${props.id}`}>
        <div className={'elementContainer'}>
            <p className={'id'}>{props.id}</p>
            <p className={'electronegativity'}>{props.electronegativity}</p>
            <p className={'symbol'} style={{color: categories[props.category].color}}>{props.symbol}</p>
            <p className={'name'}>{props.name}</p>
        </div>
    </Link>
);
/* eslint-enable react/forbid-dom-props */
