import React from 'react';
import { ECrystalStructure } from '../../../Interfaces';
import './styles.scss';

interface ICrystalIcon {
    name?: ECrystalStructure;
}

export const CrystalIcon: React.FC<ICrystalIcon> = (props) => {
    if (props.name) {
        return <img src={require(`../../../Assets/img/crystals/blu/${props.name}.png`)} className={'crystalIcon'} alt={'Crystal icon'} />;
    } else {
        return <></>;
    }
};
