import { ICategory } from '../../Interfaces';

export const categories: ICategory[] = [
    {name: 'N/A', color: '#B79664'},
    {name: 'Alkali Metal', color: '#F05C3B'},
    {name: 'Alkaline Earth', color: '#F1913E'},
    {name: 'Transition Metal', color: '#FDDB36'},
    {name: 'Basic Metal', color: '#8AC74D'},
    {name: 'Semimetal', color: '#1CB9CE'},
    {name: 'Nonmetal', color: '#98689C'},
    {name: 'Halogen', color: '#E21A77'},
    {name: 'Noble Gas', color: '#B79664'},
    {name: 'Lathanide', color: '#6EA69D'},
    {name: 'Actinide', color: '#C5707C'}
];