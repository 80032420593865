import React from 'react';
import './styles.scss';

interface IShellIcon {
    id: number;
}

export const ShellIcon: React.FC<IShellIcon> = (props) => (
    <img src={require(`../../../Assets/img/shells/${props.id}.png`)} className={'shellIcon'} alt={'Electron configuration'} />
);
