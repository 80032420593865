import { ECrystalStructure, EPhase, IElement } from '../../Interfaces';

export const elements: IElement[] = [
  {
    'id': 1,
    'symbol': 'H',
    'name': 'Hydrogen',
    'mass': 1.0078,
    'category': 6,
    'discover': 'Cavendish, Henry',
    'discoveryYear': 1776,
    'etymology': 'Greek via Latin and French',
    'meaning': 'Creation of water',
    'electronegativity': 2.2,
    'firstCrystalStructure': ECrystalStructure.HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -259,
    'boiling': -253,
    'density': 0.09,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 2,
    'symbol': 'He',
    'name': 'Helium',
    'mass': 4.0026,
    'category': 8,
    'discover': 'Ramsey, Sir William & Cleve, Per Teodor',
    'discoveryYear': 1895,
    'etymology': 'Greek',
    'meaning': 'Sun',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -999,
    'boiling': -269,
    'density': 0.18,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 3,
    'symbol': 'Li',
    'name': 'Lithium',
    'mass': 6.938,
    'category': 1,
    'discover': 'Arfvedson, Johan August',
    'discoveryYear': 1817,
    'etymology': 'Greek',
    'meaning': 'Stone',
    'electronegativity': 0.98,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 180,
    'boiling': 1347,
    'density': 0.53,
    radioactive: false,
    parentheses: false,
    'flame': '#E82249'
  },
  {
    'id': 4,
    'symbol': 'Be',
    'name': 'Beryllium',
    'mass': 9.0122,
    'category': 2,
    'discover': 'Vauquelin, Nicholas Louis',
    'discoveryYear': 1797,
    'etymology': 'Sanskrit via Latin and Greek',
    'meaning': 'The gemstone beryl',
    'electronegativity': 1.57,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1278,
    'boiling': 2970,
    'density': 1.85,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 5,
    'symbol': 'B',
    'name': 'Boron',
    'mass': 10.806,
    'category': 5,
    'discover': 'Davy, Sir Humphry & Gay-Lussac, Louis-Joseph',
    'discoveryYear': 1808,
    'etymology': 'Arabic',
    'meaning': 'Refers to sodium borate',
    'electronegativity': 2.04,
    'firstCrystalStructure': ECrystalStructure.RHOMBO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2300,
    'boiling': 2550,
    'density': 2.34,
    radioactive: false,
    parentheses: false,
    'flame': '#40A635'
  },
  {
    'id': 6,
    'symbol': 'C',
    'name': 'Carbon',
    'mass': 12.009,
    'category': 6,
    'discover': 'unknown',
    'discoveryYear': '~ 1694',
    'etymology': 'Latin via French',
    'meaning': 'Charcoal',
    'electronegativity': 2.55,
    'firstCrystalStructure': ECrystalStructure.HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 3500,
    'boiling': 4827,
    'density': 2.26,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 7,
    'symbol': 'N',
    'name': 'Nitrogen',
    'mass': 14.006,
    'category': 6,
    'discover': 'Rutherford, Daniel',
    'discoveryYear': 1772,
    'etymology': 'Greek via Latin and French',
    'meaning': 'To form niter',
    'electronegativity': 3.04,
    'firstCrystalStructure': ECrystalStructure.HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -210,
    'boiling': -196,
    'density': 1.25,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 8,
    'symbol': 'O',
    'name': 'Oxygen',
    'mass': 15.999,
    'category': 6,
    'discover': 'Priestley, Joseph & Scheele, Carl Wilhelm',
    'discoveryYear': 1774,
    'etymology': 'Greek via French',
    'meaning': 'To bring forth acid',
    'electronegativity': 3.44,
    'firstCrystalStructure': ECrystalStructure.CUBIC,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -218,
    'boiling': -183,
    'density': 1.43,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 9,
    'symbol': 'F',
    'name': 'Fluorine',
    'mass': 18.998,
    'category': 7,
    'discover': 'Moissan, Henri',
    'discoveryYear': 1886,
    'etymology': 'Latin',
    'meaning': 'Named after fluorspar',
    'electronegativity': 3.98,
    'firstCrystalStructure': ECrystalStructure.CUBIC,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -220,
    'boiling': -188,
    'density': 1.7,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 10,
    'symbol': 'Ne',
    'name': 'Neon',
    'mass': 20.18,
    'category': 8,
    'discover': 'Ramsay, William & Travers, Morris',
    'discoveryYear': 1898,
    'etymology': 'Greek',
    'meaning': 'New',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -249,
    'boiling': -246,
    'density': 0.9,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 11,
    'symbol': 'Na',
    'name': 'Sodium',
    'mass': 22.99,
    'category': 1,
    'discover': 'Davy, Humphry',
    'discoveryYear': 1807,
    'etymology': 'Modern latin from Greek',
    'meaning': 'Natural soda - a salit type',
    'electronegativity': 0.93,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 98,
    'boiling': 883,
    'density': 0.97,
    radioactive: false,
    parentheses: false,
    'flame': '#F9B11E'
  },
  {
    'id': 12,
    'symbol': 'Mg',
    'name': 'Magnesium',
    'mass': 24.304,
    'category': 2,
    'discover': 'Black, Joseph',
    'discoveryYear': 1755,
    'etymology': 'Greek',
    'meaning': 'Location discovered',
    'electronegativity': 1.31,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 639,
    'boiling': 1090,
    'density': 1.74,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 13,
    'symbol': 'Al',
    'name': 'Aluminum',
    'mass': 26.982,
    'category': 4,
    'discover': 'Oersted, Hans Christian',
    'discoveryYear': 1825,
    'etymology': 'Latin',
    'meaning': 'Alum (literally: bitter salt)',
    'electronegativity': 1.61,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 660,
    'boiling': 2467,
    'density': 2.7,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 14,
    'symbol': 'Si',
    'name': 'Silicon',
    'mass': 28.084,
    'category': 5,
    'discover': 'Berzelius, Jöns Jacob',
    'discoveryYear': 1824,
    'etymology': 'Latin',
    'meaning': 'Flint',
    'electronegativity': 1.9,
    'firstCrystalStructure': ECrystalStructure.CU_DIA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1410,
    'boiling': 2355,
    'density': 2.33,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 15,
    'symbol': 'P',
    'name': 'Phosphorus',
    'mass': 30.974,
    'category': 6,
    'discover': 'Brandt, Hennig',
    'discoveryYear': 1669,
    'etymology': 'Greek via Latin',
    'meaning': 'Light-bearer',
    'electronegativity': 2.19,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 44,
    'boiling': 280,
    'density': 1.82,
    radioactive: false,
    parentheses: false,
    'flame': '#86E2B9'
  },
  {
    'id': 16,
    'symbol': 'S',
    'name': 'Sulfur',
    'mass': 32.059,
    'category': 6,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'Latin',
    'meaning': 'To burn',
    'electronegativity': 2.58,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 113,
    'boiling': 445,
    'density': 2.07,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 17,
    'symbol': 'Cl',
    'name': 'Chlorine',
    'mass': 35.446,
    'category': 7,
    'discover': 'Scheele, Carl Wilhelm',
    'discoveryYear': 1774,
    'etymology': 'Greek',
    'meaning': 'Pale green',
    'electronegativity': 3.16,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -101,
    'boiling': -35,
    'density': 3.21,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 18,
    'symbol': 'Ar',
    'name': 'Argon',
    'mass': 39.948,
    'category': 8,
    'discover': 'Ramsay, Sir William & Strutt, John',
    'discoveryYear': 1894,
    'etymology': 'Greek',
    'meaning': 'Inactive',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -189,
    'boiling': -186,
    'density': 0.86,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 19,
    'symbol': 'K',
    'name': 'Potassium',
    'mass': 39.098,
    'category': 1,
    'discover': 'Davy, Humphry',
    'discoveryYear': 1807,
    'etymology': 'Latin via Arabic',
    'meaning': 'Calcined ashes',
    'electronegativity': 0.82,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 64,
    'boiling': 760,
    'density': 1.78,
    radioactive: false,
    parentheses: false,
    'flame': '#B478B2'
  },
  {
    'id': 20,
    'symbol': 'Ca',
    'name': 'Calcium',
    'mass': 40.078,
    'category': 2,
    'discover': 'Davy, Humphry',
    'discoveryYear': 1808,
    'etymology': 'Latin via greek',
    'meaning': 'Pebble and/or limestone',
    'electronegativity': 1,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 839,
    'boiling': 774,
    'density': 1.55,
    radioactive: false,
    parentheses: false,
    'flame': '#DD551A'
  },
  {
    'id': 21,
    'symbol': 'Sc',
    'name': 'Scandium',
    'mass': 44.956,
    'category': 3,
    'discover': 'Nilson, Lars Fredrik',
    'discoveryYear': 1879,
    'etymology': 'Latin',
    'meaning': 'scandinavia',
    'electronegativity': 1.36,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1539,
    'boiling': 1484,
    'density': 3,
    radioactive: false,
    parentheses: false,
    'flame': '#E38842'
  },
  {
    'id': 22,
    'symbol': 'Ti',
    'name': 'Titanium',
    'mass': 47.867,
    'category': 3,
    'discover': 'Gregor, William & Klaproth, Martin Heinrich',
    'discoveryYear': 1791,
    'etymology': 'greek',
    'meaning': 'earth',
    'electronegativity': 1.54,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1660,
    'boiling': 2832,
    'density': 4.54,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 23,
    'symbol': 'V',
    'name': 'Vanadium',
    'mass': 50.942,
    'category': 3,
    'discover': 'Del Rio, Andrés Manuel & Sefström, Nils Gabriel',
    'discoveryYear': 1830,
    'etymology': 'Old Norse',
    'meaning': 'The Vanr godness Freyja',
    'electronegativity': 1.63,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1890,
    'boiling': 3287,
    'density': 6.11,
    radioactive: false,
    parentheses: false,
    'flame': '#D3D57A'
  },
  {
    'id': 24,
    'symbol': 'Cr',
    'name': 'Chromium',
    'mass': 51.996,
    'category': 3,
    'discover': 'Vauquelin',
    'discoveryYear': 1797,
    'etymology': 'greek via French',
    'meaning': 'Colour',
    'electronegativity': 1.66,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1857,
    'boiling': 3380,
    'density': 7.19,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 25,
    'symbol': 'Mn',
    'name': 'Manganese',
    'mass': 54.938,
    'category': 3,
    'discover': 'Gahn, Johan Gottlieb',
    'discoveryYear': 1774,
    'etymology': 'Greek via Latin and Italian',
    'meaning': 'location found',
    'electronegativity': 1.55,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1245,
    'boiling': 2672,
    'density': 7.43,
    radioactive: false,
    parentheses: false,
    'flame': '#D3D875'
  },
  {
    'id': 26,
    'symbol': 'Fe',
    'name': 'Iron',
    'mass': 55.845,
    'category': 3,
    'discover': 'unknown',
    'discoveryYear': 1774,
    'etymology': 'Latin',
    'meaning': 'iron (strong/holy metal)',
    'electronegativity': 1.83,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1535,
    'boiling': 1962,
    'density': 7.87,
    radioactive: false,
    parentheses: false,
    'flame': '#FEC00D'
  },
  {
    'id': 27,
    'symbol': 'Co',
    'name': 'Cobalt',
    'mass': 58.933,
    'category': 3,
    'discover': 'Brandt, Georg',
    'discoveryYear': 1735,
    'etymology': 'german',
    'meaning': 'evil spirit',
    'electronegativity': 1.88,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1495,
    'boiling': 2750,
    'density': 8.9,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 28,
    'symbol': 'Ni',
    'name': 'Nickel',
    'mass': 58.693,
    'category': 3,
    'discover': 'Cronstedt, Alex Fredrik',
    'discoveryYear': 1751,
    'etymology': 'swedish via German',
    'meaning': 'Copper-coloured ore',
    'electronegativity': 1.91,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1453,
    'boiling': 2870,
    'density': 8.9,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 29,
    'symbol': 'Cu',
    'name': 'Copper',
    'mass': 63.546,
    'category': 3,
    'discover': 'unknown',
    'discoveryYear': 1751,
    'etymology': 'greek via Latin',
    'meaning': 'cyprus',
    'electronegativity': 1.9,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1083,
    'boiling': 2732,
    'density': 8.96,
    radioactive: false,
    parentheses: false,
    'flame': '#5BB987'
  },
  {
    'id': 30,
    'symbol': 'Zn',
    'name': 'Zinc',
    'mass': 65.38,
    'category': 3,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'german',
    'meaning': 'cornet',
    'electronegativity': 1.65,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 420,
    'boiling': 2567,
    'density': 7.13,
    radioactive: false,
    parentheses: false,
    'flame': '#B4DDCC'
  },
  {
    'id': 31,
    'symbol': 'Ga',
    'name': 'Gallium',
    'mass': 69.723,
    'category': 4,
    'discover': 'Lecoq de Boisbaudran, Paul-Émile',
    'discoveryYear': 1875,
    'etymology': 'latin',
    'meaning': 'Gaul (ancient France)',
    'electronegativity': 1.81,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 30,
    'boiling': 907,
    'density': 5.91,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 32,
    'symbol': 'Ge',
    'name': 'Germanium',
    'mass': 72.63,
    'category': 5,
    'discover': 'Winkler, Clemens A.',
    'discoveryYear': 1886,
    'etymology': 'Latin',
    'meaning': 'germany',
    'electronegativity': 2.01,
    'firstCrystalStructure': ECrystalStructure.CU_DIA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 937,
    'boiling': 2403,
    'density': 5.32,
    radioactive: false,
    parentheses: false,
    'flame': '#5F9ACF'
  },
  {
    'id': 33,
    'symbol': 'As',
    'name': 'Arsenic',
    'mass': 74.922,
    'category': 5,
    'discover': 'Magnus, Albertus',
    'discoveryYear': 1886,
    'etymology': 'persian via Greek and Latin',
    'meaning': 'orpiment',
    'electronegativity': 2.18,
    'firstCrystalStructure': ECrystalStructure.RHOMBO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 816.8,
    'boiling': 2830,
    'density': 5.72,
    radioactive: false,
    parentheses: false,
    'flame': '#91BDE6'
  },
  {
    'id': 34,
    'symbol': 'Se',
    'name': 'Selenium',
    'mass': 78.971,
    'category': 6,
    'discover': 'Berzelius, Jöns Jacob',
    'discoveryYear': 1817,
    'etymology': 'greek',
    'meaning': 'moon',
    'electronegativity': 2.55,
    'firstCrystalStructure': ECrystalStructure.HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 217,
    'boiling': 613,
    'density': 4.79,
    radioactive: false,
    parentheses: false,
    'flame': '#5E97D2'
  },
  {
    'id': 35,
    'symbol': 'Br',
    'name': 'Bromine',
    'mass': 79.901,
    'category': 7,
    'discover': 'Balard, Antoine-Jérôme',
    'discoveryYear': 1826,
    'etymology': 'greek via French',
    'meaning': 'dirt or stench',
    'electronegativity': 2.96,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.LIQUID,
    'melting': -7,
    'boiling': 685,
    'density': 3.12,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 36,
    'symbol': 'Kr',
    'name': 'Krypton',
    'mass': 83.798,
    'category': 8,
    'discover': 'Ramsay, Wil & Travers, Morris Willliam',
    'discoveryYear': 1898,
    'etymology': 'greek',
    'meaning': 'hidden',
    'electronegativity': 3,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -157,
    'boiling': 58.8,
    'density': 3.75,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 37,
    'symbol': 'Rb',
    'name': 'Rubidium',
    'mass': 85.468,
    'category': 1,
    'discover': 'Bunsen, Robert Wilhelm & Kirchhoff, Gustav Robert',
    'discoveryYear': 1861,
    'etymology': 'latin',
    'meaning': 'deepest red',
    'electronegativity': 0.82,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 39,
    'boiling': -153,
    'density': 1.63,
    radioactive: false,
    parentheses: false,
    'flame': '#CA7087'
  },
  {
    'id': 38,
    'symbol': 'Sr',
    'name': 'Strontium',
    'mass': 87.62,
    'category': 2,
    'discover': 'Crawford, Adair',
    'discoveryYear': 1790,
    'etymology': 'Scottish Gaelic via English',
    'meaning': 'Location discovered',
    'electronegativity': 0.95,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 769,
    'boiling': 688,
    'density': 2.54,
    radioactive: false,
    parentheses: false,
    'flame': '#ED3831'
  },
  {
    'id': 39,
    'symbol': 'Y',
    'name': 'Yttrium',
    'mass': 88.906,
    'category': 3,
    'discover': 'Gadolin, Johan',
    'discoveryYear': 1794,
    'etymology': 'swedish',
    'meaning': 'location discovered',
    'electronegativity': 1.22,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1523,
    'boiling': 1384,
    'density': 4.47,
    radioactive: false,
    parentheses: false,
    'flame': '#C70840'
  },
  {
    'id': 40,
    'symbol': 'Zr',
    'name': 'Zirconium',
    'mass': 91.224,
    'category': 3,
    'discover': 'Klaproth, Martin Heinrich',
    'discoveryYear': 1789,
    'etymology': 'persian via Arabic',
    'meaning': 'gold-like',
    'electronegativity': 1.33,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1852,
    'boiling': 3337,
    'density': 6.51,
    radioactive: false,
    parentheses: false,
    'flame': '#C05558'
  },
  {
    'id': 41,
    'symbol': 'Nb',
    'name': 'Niobium',
    'mass': 92.906,
    'category': 3,
    'discover': 'Hatchet, Charles',
    'discoveryYear': 1801,
    'etymology': 'greek',
    'meaning': 'snowy',
    'electronegativity': 1.6,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2468,
    'boiling': 4377,
    'density': 8.57,
    radioactive: false,
    parentheses: false,
    'flame': '#5DBAAB'
  },
  {
    'id': 42,
    'symbol': 'Mo',
    'name': 'Molybdenum',
    'mass': 95.95,
    'category': 3,
    'discover': 'Scheele, Karl',
    'discoveryYear': 1781,
    'etymology': 'greek',
    'meaning': 'lead-like',
    'electronegativity': 2.16,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2617,
    'boiling': 4927,
    'density': 10.22,
    radioactive: false,
    parentheses: false,
    'flame': '#CAD13F'
  },
  {
    'id': 43,
    'symbol': 'Tc',
    'name': 'Technetium',
    'mass': 98,
    'category': 3,
    'discover': 'Perrier, Carlo & Segrè, Emilio',
    'discoveryYear': 1937,
    'etymology': 'greek',
    'meaning': 'artificial',
    'electronegativity': 1.9,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2200,
    'boiling': 4612,
    'density': 11.5,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 44,
    'symbol': 'Ru',
    'name': 'Ruthenium',
    'mass': 101.07,
    'category': 3,
    'discover': 'Klaus, Karl Karlovich',
    'discoveryYear': 1844,
    'etymology': 'latin',
    'meaning': 'the area Kievan Rus\'',
    'electronegativity': 2.2,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2250,
    'boiling': 4877,
    'density': 12.37,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 45,
    'symbol': 'Rh',
    'name': 'Rhodium',
    'mass': 102.91,
    'category': 3,
    'discover': 'Wollaston, William Hyde',
    'discoveryYear': 1803,
    'etymology': 'greek',
    'meaning': 'rose',
    'electronegativity': 2.28,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1966,
    'boiling': 3900,
    'density': 12.41,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 46,
    'symbol': 'Pd',
    'name': 'Palladium',
    'mass': 106.42,
    'category': 3,
    'discover': 'Wollaston, William Hyde',
    'discoveryYear': 1803,
    'etymology': 'greek via Latin',
    'meaning': 'little maiden',
    'electronegativity': 2.2,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1552,
    'boiling': 3727,
    'density': 12.02,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 47,
    'symbol': 'Ag',
    'name': 'Silver',
    'mass': 107.87,
    'category': 3,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'latin',
    'meaning': 'to refine, melt',
    'electronegativity': 1.93,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 962,
    'boiling': 2927,
    'density': 10.5,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 48,
    'symbol': 'Cd',
    'name': 'Cadmium',
    'mass': 112.41,
    'category': 3,
    'discover': 'Stromeyer, Prof. Friedrich',
    'discoveryYear': 1817,
    'etymology': 'greek/Latin',
    'meaning': 'calamine or cadmean earth',
    'electronegativity': 1.69,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 321,
    'boiling': 2212,
    'density': 8.65,
    radioactive: false,
    parentheses: false,
    'flame': '#B74E31'
  },
  {
    'id': 49,
    'symbol': 'In',
    'name': 'Indium',
    'mass': 114.82,
    'category': 4,
    'discover': 'Reich, Ferdinand & Richter, Hieronymus',
    'discoveryYear': 1863,
    'etymology': 'greek via Latin and English',
    'meaning': 'the blue volour indigo',
    'electronegativity': 1.78,
    'firstCrystalStructure': ECrystalStructure.TETRA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 157,
    'boiling': 765,
    'density': 7.31,
    radioactive: false,
    parentheses: false,
    'flame': '#5186C5'
  },
  {
    'id': 50,
    'symbol': 'Sn',
    'name': 'Tin',
    'mass': 118.71,
    'category': 4,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'latin',
    'meaning': 'tin',
    'electronegativity': 1.96,
    'firstCrystalStructure': ECrystalStructure.TETRA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 232,
    'boiling': 2000,
    'density': 7.31,
    radioactive: false,
    parentheses: false,
    'flame': '#92CBD4'
  },
  {
    'id': 51,
    'symbol': 'Sb',
    'name': 'Antimony',
    'mass': 121.76,
    'category': 5,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'greek via Medieval Latin',
    'meaning': 'various',
    'electronegativity': 2.05,
    'firstCrystalStructure': ECrystalStructure.RHOMBO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 630,
    'boiling': 2270,
    'density': 6.68,
    radioactive: false,
    parentheses: false,
    'flame': '#B7DCB5'
  },
  {
    'id': 52,
    'symbol': 'Te',
    'name': 'Tellurium',
    'mass': 127.6,
    'category': 5,
    'discover': 'Müller von Reichenstein, Franz Joseph',
    'discoveryYear': 1782,
    'etymology': 'latin',
    'meaning': 'earth',
    'electronegativity': 2.1,
    'firstCrystalStructure': ECrystalStructure.HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 449,
    'boiling': 1750,
    'density': 4.93,
    radioactive: false,
    parentheses: false,
    'flame': '#B1F1B0'
  },
  {
    'id': 53,
    'symbol': 'I',
    'name': 'Iodine',
    'mass': 126.9,
    'category': 7,
    'discover': 'Courtois, Bernard',
    'discoveryYear': 1811,
    'etymology': 'greek via French',
    'meaning': 'violet',
    'electronegativity': 2.66,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 114,
    'boiling': 990,
    'density': 6.24,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 54,
    'symbol': 'Xe',
    'name': 'Xenon',
    'mass': 131.29,
    'category': 8,
    'discover': 'Ramsay, William & Travers, Morris William',
    'discoveryYear': 1898,
    'etymology': 'greek',
    'meaning': 'foreign',
    'electronegativity': 2.6,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -112,
    'boiling': 184,
    'density': 5.9,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 55,
    'symbol': 'Cs',
    'name': 'Cesium',
    'mass': 132.91,
    'category': 1,
    'discover': 'Kirchhoff, Gustav & Bunsen, Robert',
    'discoveryYear': 1860,
    'etymology': 'latin',
    'meaning': 'blue-grey',
    'electronegativity': 0.79,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 29,
    'boiling': -108,
    'density': 1.87,
    radioactive: false,
    parentheses: false,
    'flame': '#9791BE'
  },
  {
    'id': 56,
    'symbol': 'Ba',
    'name': 'Barium',
    'mass': 137.33,
    'category': 2,
    'discover': 'Davy, Humphry',
    'discoveryYear': 1808,
    'etymology': 'greek via modern Latin',
    'meaning': 'heavy',
    'electronegativity': 0.89,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 725,
    'boiling': 678,
    'density': 3.59,
    radioactive: false,
    parentheses: false,
    'flame': '#A7C713'
  },
  {
    'id': 57,
    'symbol': 'La',
    'name': 'Lanthanum',
    'mass': 138.91,
    'category': 9,
    'discover': 'Mosander, Carl Gustav',
    'discoveryYear': 1839,
    'etymology': 'greek',
    'meaning': 'to lie hidden',
    'electronegativity': 1.1,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 920,
    'boiling': 1140,
    'density': 6.15,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 58,
    'symbol': 'Ce',
    'name': 'Cerium',
    'mass': 140.12,
    'category': 9,
    'discover': 'Hisinger, Wilhelm & Berzelius, Jöns Jacob',
    'discoveryYear': 1803,
    'etymology': 'latin',
    'meaning': 'grain, bread',
    'electronegativity': 1.12,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': ECrystalStructure.CU_FACE,
    'phase': EPhase.SOLID,
    'melting': 795,
    'boiling': 3469,
    'density': 6.77,
    radioactive: false,
    parentheses: false,
    'flame': '#425BC6'
  },
  {
    'id': 59,
    'symbol': 'Pr',
    'name': 'Praseodymium',
    'mass': 140.91,
    'category': 9,
    'discover': 'Von Welsbach, Baron Auer',
    'discoveryYear': 1885,
    'etymology': 'greek',
    'meaning': 'green twin',
    'electronegativity': 1.13,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 935,
    'boiling': 3257,
    'density': 6.77,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 60,
    'symbol': 'Nd',
    'name': 'Neodymium',
    'mass': 144.24,
    'category': 9,
    'discover': 'Von Welsbach, Baron Auer',
    'discoveryYear': 1885,
    'etymology': 'greek',
    'meaning': 'new twin',
    'electronegativity': 1.14,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1010,
    'boiling': 3127,
    'density': 7.01,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 61,
    'symbol': 'Pm',
    'name': 'Promethium',
    'mass': 145,
    'category': 9,
    'discover': 'Marinsky, J. A. & Glendenin, L. E.',
    'discoveryYear': 1945,
    'etymology': 'greek',
    'meaning': 'foretthought',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1100,
    'boiling': 3127,
    'density': 7.3,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 62,
    'symbol': 'Sm',
    'name': 'Samarium',
    'mass': 150.36,
    'category': 9,
    'discover': 'Lecoq de Boisbaudran, Paul-Émile',
    'discoveryYear': 1879,
    'etymology': 'unknown',
    'meaning': 'a mineral named samarskite',
    'electronegativity': 1.17,
    'firstCrystalStructure': ECrystalStructure.RHOMBO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1072,
    'boiling': 3000,
    'density': 7.52,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 63,
    'symbol': 'Eu',
    'name': 'Europium',
    'mass': 151.96,
    'category': 9,
    'discover': 'Demacay, Had',
    'discoveryYear': 1901,
    'etymology': 'acient Greek',
    'meaning': 'well-watered',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 822,
    'boiling': 1900,
    'density': 5.24,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 64,
    'symbol': 'Gd',
    'name': 'Gadolinium',
    'mass': 157.25,
    'category': 9,
    'discover': 'De Marignac, Charles Galissard',
    'discoveryYear': 1880,
    'etymology': 'unknown',
    'meaning': 'in honour of Johan Gadolin',
    'electronegativity': 1.2,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1311,
    'boiling': 1597,
    'density': 7.9,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 65,
    'symbol': 'Tb',
    'name': 'Terbium',
    'mass': 158.93,
    'category': 9,
    'discover': 'Mosander, Carl Gustav',
    'discoveryYear': 1843,
    'etymology': 'swedish',
    'meaning': 'location discovered',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1360,
    'boiling': 3233,
    'density': 8.23,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 66,
    'symbol': 'Dy',
    'name': 'Dysprosium',
    'mass': 162.5,
    'category': 9,
    'discover': 'Lecoq de Boisbaudran, Paul-Émile',
    'discoveryYear': 1886,
    'etymology': 'greek',
    'meaning': 'hard to get at',
    'electronegativity': 1.22,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1412,
    'boiling': 3041,
    'density': 8.55,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 67,
    'symbol': 'Ho',
    'name': 'Holmium',
    'mass': 164.93,
    'category': 9,
    'discover': 'Delafontaine, Marc & Soret, Louis',
    'discoveryYear': 1867,
    'etymology': 'latin',
    'meaning': 'stockholm',
    'electronegativity': 1.23,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1470,
    'boiling': 2562,
    'density': 8.8,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 68,
    'symbol': 'Er',
    'name': 'Erbium',
    'mass': 167.26,
    'category': 9,
    'discover': 'Mosander, Carl Gustav',
    'discoveryYear': 1842,
    'etymology': 'swedish',
    'meaning': 'location discovered',
    'electronegativity': 1.24,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1522,
    'boiling': 2720,
    'density': 9.07,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 69,
    'symbol': 'Tm',
    'name': 'Thulium',
    'mass': 168.93,
    'category': 9,
    'discover': 'Cleve, Per Teodor',
    'discoveryYear': 1879,
    'etymology': 'greek',
    'meaning': 'a mythical country',
    'electronegativity': 1.25,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1545,
    'boiling': 2510,
    'density': 9.32,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 70,
    'symbol': 'Yb',
    'name': 'Ytterbium',
    'mass': 173.05,
    'category': 9,
    'discover': 'De Marignac, Jean Charles Galissard',
    'discoveryYear': 1878,
    'etymology': 'swedish',
    'meaning': 'location discovered',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 824,
    'boiling': 1727,
    'density': 6.9,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 71,
    'symbol': 'Lu',
    'name': 'Lutetium',
    'mass': 174.97,
    'category': 9,
    'discover': 'Urbain, Georges',
    'discoveryYear': 1907,
    'etymology': 'latin',
    'meaning': 'paris',
    'electronegativity': 1.27,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1656,
    'boiling': 1466,
    'density': 9.84,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 72,
    'symbol': 'Hf',
    'name': 'Hafnium',
    'mass': 178.49,
    'category': 3,
    'discover': 'Coster, Dirk & De Hevesy, George Charles',
    'discoveryYear': 1923,
    'etymology': 'latin',
    'meaning': 'copenhagen',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2150,
    'boiling': 3315,
    'density': 13.31,
    radioactive: false,
    parentheses: false,
    'flame': '#FFFFFF'
  },
  {
    'id': 73,
    'symbol': 'Ta',
    'name': 'Tantalum',
    'mass': 180.95,
    'category': 3,
    'discover': 'Ekeberg, Anders Gustav',
    'discoveryYear': 1802,
    'etymology': 'greek',
    'meaning': 'the bearer of the sufferer',
    'electronegativity': 1.5,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': ECrystalStructure.TETRA,
    'phase': EPhase.SOLID,
    'melting': 2996,
    'boiling': 5400,
    'density': 16.65,
    radioactive: false,
    parentheses: false,
    'flame': '#2F49A8'
  },
  {
    'id': 74,
    'symbol': 'W',
    'name': 'Wolfram',
    'mass': 183.84,
    'category': 3,
    'discover': 'Elhuyar, Juan José & Fausto (brother)',
    'discoveryYear': 1783,
    'etymology': undefined,
    'meaning': 'in honour of Peter Woulife',
    'electronegativity': 2.36,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 3410,
    'boiling': 5425,
    'density': 19.35,
    radioactive: false,
    parentheses: false,
    'flame': '#34A32C'
  },
  {
    'id': 75,
    'symbol': 'Re',
    'name': 'Rhenium',
    'mass': 186.21,
    'category': 3,
    'discover': 'Noddack, Walter & Tacke, Ida',
    'discoveryYear': 1925,
    'etymology': 'latin',
    'meaning': 'the Rhine',
    'electronegativity': 1.9,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 3180,
    'boiling': 5660,
    'density': 21.04,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 76,
    'symbol': 'Os',
    'name': 'Osmium',
    'mass': 190.23,
    'category': 3,
    'discover': 'Tennant, Smithson',
    'discoveryYear': 1803,
    'etymology': 'greek via Modern Latin',
    'meaning': 'a smell',
    'electronegativity': 2.2,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 3045,
    'boiling': 5627,
    'density': 22.6,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 77,
    'symbol': 'Ir',
    'name': 'Iridium',
    'mass': 192.22,
    'category': 3,
    'discover': 'Tennant, Smithson',
    'discoveryYear': 1803,
    'etymology': 'greek via Latin',
    'meaning': 'of rainbows',
    'electronegativity': 2.2,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 2410,
    'boiling': 5027,
    'density': 22.4,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 78,
    'symbol': 'Pt',
    'name': 'Platinum',
    'mass': 195.08,
    'category': 3,
    'discover': 'Scaliger, Julius',
    'discoveryYear': 1735,
    'etymology': 'spanish via Modern Latin',
    'meaning': 'little silver',
    'electronegativity': 2.28,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1772,
    'boiling': 4527,
    'density': 21.45,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 79,
    'symbol': 'Au',
    'name': 'Gold',
    'mass': 196.97,
    'category': 3,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'latin',
    'meaning': 'shining dawn',
    'electronegativity': 2.54,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1064,
    'boiling': 3827,
    'density': 19.32,
    radioactive: false,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 80,
    'symbol': 'Hg',
    'name': 'Mercury',
    'mass': 200.59,
    'category': 3,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'latin',
    'meaning': 'water-silver',
    'electronegativity': 2,
    'firstCrystalStructure': ECrystalStructure.RHOMBO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.LIQUID,
    'melting': -39,
    'boiling': 2807,
    'density': 13.55,
    radioactive: false,
    parentheses: false,
    'flame': '#D03731'
  },
  {
    'id': 81,
    'symbol': 'Tl',
    'name': 'Thallium',
    'mass': 204.38,
    'category': 4,
    'discover': 'Crookes, William',
    'discoveryYear': 1861,
    'etymology': 'greek',
    'meaning': 'green twig',
    'electronegativity': 1.62,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 303,
    'boiling': 357,
    'density': 11.85,
    radioactive: false,
    parentheses: false,
    'flame': '#23A30E'
  },
  {
    'id': 82,
    'symbol': 'Pb',
    'name': 'Lead',
    'mass': 207.2,
    'category': 4,
    'discover': 'unknown',
    'discoveryYear': 'ancient',
    'etymology': 'latin',
    'meaning': 'lead',
    'electronegativity': 2.33,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 327,
    'boiling': 1457,
    'density': 11.35,
    radioactive: false,
    parentheses: false,
    'flame': '#B3DEE6'
  },
  {
    'id': 83,
    'symbol': 'Bi',
    'name': 'Bismuth',
    'mass': 208.98,
    'category': 4,
    'discover': 'Agricola, Georgius',
    'discoveryYear': '~ 1400',
    'etymology': 'modern Latin via German',
    'meaning': 'White mass',
    'electronegativity': 2.02,
    'firstCrystalStructure': ECrystalStructure.RHOMBO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 271,
    'boiling': 1740,
    'density': 9.75,
    radioactive: false,
    parentheses: false,
    'flame': '#22BDFF'
  },
  {
    'id': 84,
    'symbol': 'Po',
    'name': 'Polonium',
    'mass': 209,
    'category': 5,
    'discover': 'Curie, Marie & Pierre',
    'discoveryYear': 1898,
    'etymology': 'latin',
    'meaning': 'poland',
    'electronegativity': 2,
    'firstCrystalStructure': ECrystalStructure.CUBIC,
    'secondCrystalStructure': ECrystalStructure.RHOMBO,
    'phase': EPhase.SOLID,
    'melting': 254,
    'boiling': 1560,
    'density': 9.3,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 85,
    'symbol': 'At',
    'name': 'Astatine',
    'mass': 210,
    'category': 7,
    'discover': 'Corson, Dale R. & Mackenzie, K. R.',
    'discoveryYear': 1940,
    'etymology': 'greek',
    'meaning': 'unstable',
    'electronegativity': 2.2,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 302,
    'boiling': 962,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 86,
    'symbol': 'Rn',
    'name': 'Radon',
    'mass': 222,
    'category': 8,
    'discover': 'Dorn, Friedrich Ernst',
    'discoveryYear': 1900,
    'etymology': 'latin via German and English',
    'meaning': 'constraction of radium eminition',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': -71,
    'boiling': 337,
    'density': 9.73,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 87,
    'symbol': 'Fr',
    'name': 'Francium',
    'mass': 223,
    'category': 1,
    'discover': 'Perey, Marguerite',
    'discoveryYear': 1939,
    'etymology': 'french',
    'meaning': 'france',
    'electronegativity': 0,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 27,
    'boiling': -62,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 88,
    'symbol': 'Ra',
    'name': 'Radium',
    'mass': 226,
    'category': 2,
    'discover': 'Curie, Marie & Pierre',
    'discoveryYear': 1898,
    'etymology': 'latin via French',
    'meaning': 'ray',
    'electronegativity': 0.9,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 700,
    'boiling': 677,
    'density': 5.5,
    radioactive: true,
    parentheses: true,
    'flame': '#D8184C'
  },
  {
    'id': 89,
    'symbol': 'Ac',
    'name': 'Actinium',
    'mass': 227,
    'category': 10,
    'discover': 'Debierne, André',
    'discoveryYear': 1899,
    'etymology': 'greek',
    'meaning': 'beam',
    'electronegativity': 1.1,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1050,
    'boiling': 1737,
    'density': 10.07,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 90,
    'symbol': 'Th',
    'name': 'Thorium',
    'mass': 232.04,
    'category': 10,
    'discover': 'Berzelius, Jöns Jacob',
    'discoveryYear': 1829,
    'etymology': 'old Norse',
    'meaning': 'thunder',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1750,
    'boiling': 3200,
    'density': 15.4,
    radioactive: true,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 91,
    'symbol': 'Pa',
    'name': 'Protactinium',
    'mass': 231.04,
    'category': 10,
    'discover': 'Soddy, Frederick; Cranston, John & Hahn, Otto',
    'discoveryYear': 1913,
    'etymology': 'greek',
    'meaning': 'first beam element',
    'electronegativity': 1.5,
    'firstCrystalStructure': ECrystalStructure.TETRA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1568,
    'boiling': 4790,
    'density': 11.72,
    radioactive: true,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 92,
    'symbol': 'U',
    'name': 'Uranium',
    'mass': 238.03,
    'category': 10,
    'discover': 'Klaproth, Martin Heinrich',
    'discoveryYear': 1789,
    'etymology': 'greek via Latin',
    'meaning': 'sky',
    'electronegativity': 1.38,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1132,
    'boiling': undefined,
    'density': 20.2,
    radioactive: true,
    parentheses: false,
    'flame': ''
  },
  {
    'id': 93,
    'symbol': 'Np',
    'name': 'Neptunium',
    'mass': 237,
    'category': 10,
    'discover': 'McMillan, Edwin M. & Abelson, Philip H.',
    'discoveryYear': 1940,
    'etymology': 'Latin',
    'meaning': 'neptune',
    'electronegativity': 1.36,
    'firstCrystalStructure': ECrystalStructure.ORTHO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 640,
    'boiling': 3818,
    'density': 18.95,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 94,
    'symbol': 'Pu',
    'name': 'Plutonium',
    'mass': 244,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1940,
    'etymology': 'greek via Latin',
    'meaning': 'the god if wealth',
    'electronegativity': 1.28,
    'firstCrystalStructure': ECrystalStructure.MONO,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 640,
    'boiling': 3902,
    'density': 13.67,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 95,
    'symbol': 'Am',
    'name': 'Americium',
    'mass': 243,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1944,
    'etymology': 'unknown',
    'meaning': 'america',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 994,
    'boiling': 3235,
    'density': 19.84,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 96,
    'symbol': 'Cm',
    'name': 'Curium',
    'mass': 247,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1944,
    'etymology': undefined,
    'meaning': 'in honour of Marie Curie',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1340,
    'boiling': 2607,
    'density': 13.5,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 97,
    'symbol': 'Bk',
    'name': 'Berkelium',
    'mass': 247,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1949,
    'etymology': 'anglo-Saxon via English',
    'meaning': 'location discovered',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 986,
    'boiling': undefined,
    'density': 14.78,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 98,
    'symbol': 'Cf',
    'name': 'Californium',
    'mass': 251,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1950,
    'etymology': 'english',
    'meaning': 'california',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.DOUBLE_HEXA,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 900,
    'boiling': undefined,
    'density': 15.1,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 99,
    'symbol': 'Es',
    'name': 'Einsteinium',
    'mass': 252,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1952,
    'etymology': undefined,
    'meaning': 'in honour of Einstein',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 860,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 100,
    'symbol': 'Fm',
    'name': 'Fermium',
    'mass': 257,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1952,
    'etymology': undefined,
    'meaning': 'in honour of Enrico Fermi',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1527,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 101,
    'symbol': 'Md',
    'name': 'Mendelevium',
    'mass': 258,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1955,
    'etymology': undefined,
    'meaning': 'in honour of Mendeleyav',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 102,
    'symbol': 'No',
    'name': 'Nobelium',
    'mass': 259,
    'category': 10,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1958,
    'etymology': undefined,
    'meaning': 'in honour of Alfred Nobel',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 827,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 103,
    'symbol': 'Lr',
    'name': 'Lawrencium',
    'mass': 262,
    'category': 10,
    'discover': 'Ghiorso, Albert',
    'discoveryYear': 1961,
    'etymology': undefined,
    'meaning': 'in honor of Ernst Lawrence',
    'electronegativity': 1.3,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 1627,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 104,
    'symbol': 'Rf',
    'name': 'Rutherfordium',
    'mass': 261,
    'category': 3,
    'discover': 'Flerow, Iwan or Ghiorso, Albert',
    'discoveryYear': 1964,
    'etymology': undefined,
    'meaning': 'in honour of E. Rutherford',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 105,
    'symbol': 'Db',
    'name': 'Dubnium',
    'mass': 262,
    'category': 3,
    'discover': 'Flerow or Ghiorso',
    'discoveryYear': 1967,
    'etymology': 'Russian',
    'meaning': 'location discovered',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 106,
    'symbol': 'Sg',
    'name': 'Seaborgium',
    'mass': 266,
    'category': 3,
    'discover': 'Seaborg, Glenn T.',
    'discoveryYear': 1974,
    'etymology': undefined,
    'meaning': 'in ohonour of Glenn Seaborg',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 107,
    'symbol': 'Bh',
    'name': 'Bohrium',
    'mass': 264,
    'category': 3,
    'discover': 'Oganessian',
    'discoveryYear': 1981,
    'etymology': undefined,
    'meaning': 'in honour of Niels Bohr',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 108,
    'symbol': 'Hs',
    'name': 'Hassium',
    'mass': 277,
    'category': 3,
    'discover': 'Armbruster, Paula & Muenzenberg, Dr. Gottfried',
    'discoveryYear': 1984,
    'etymology': 'latin',
    'meaning': 'the German state Hesse',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 109,
    'symbol': 'Mt',
    'name': 'Meitnerium',
    'mass': 268,
    'category': 3,
    'discover': 'Armbruster, Paula & Muenzenberg, Dr. Gottfried',
    'discoveryYear': 1982,
    'etymology': undefined,
    'meaning': 'in honour of Lise Meitner',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 110,
    'symbol': 'Ds',
    'name': 'Darmstadtium',
    'mass': 281,
    'category': 3,
    'discover': 'Armbruster, Paula & Muenzenberg, Dr. Gottfried',
    'discoveryYear': 1994,
    'etymology': 'german',
    'meaning': 'location discovered',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 111,
    'symbol': 'Rg',
    'name': 'Roentgenium',
    'mass': 272,
    'category': 3,
    'discover': 'Hofmann, Sigurd',
    'discoveryYear': 1994,
    'etymology': undefined,
    'meaning': 'in honour of W. C. Röntgen',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.CU_BODY,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 112,
    'symbol': 'Cn',
    'name': 'Copemicium',
    'mass': 285,
    'category': 3,
    'discover': 'Armbruster, Paula & Muenzenberg, Dr. Gottfried',
    'discoveryYear': 1996,
    'etymology': undefined,
    'meaning': 'in honour of N. Copernicus',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.HEXA_CLOSE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': undefined,
    'boiling': undefined,
    'density': undefined,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 113,
    'symbol': 'Nh',
    'name': 'Nihonium',
    'mass': 286,
    'category': 4,
    'discover': 'RIKEN (Japan, first undisputed claim 2004), JINR',
    'discoveryYear': 1998,
    'etymology': 'japanese',
    'meaning': 'japan',
    'electronegativity': undefined,
    'firstCrystalStructure': undefined,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 430,
    'boiling': 1130,
    'density': 16,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 114,
    'symbol': 'Fi',
    'name': 'Flerovium',
    'mass': 289,
    'category': 4,
    'discover': 'Joint Institute for Nuclear Research (JINR) and L',
    'discoveryYear': 1999,
    'etymology': 'russian',
    'meaning': 'in honour of Georgy Flerov',
    'electronegativity': undefined,
    'firstCrystalStructure': undefined,
    'secondCrystalStructure': undefined,
    'phase': EPhase.GAS,
    'melting': undefined,
    'boiling': 60,
    'density': 14,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 115,
    'symbol': 'Mc',
    'name': 'Moscovium',
    'mass': 290,
    'category': 4,
    'discover': 'Joint Institute for Nuclear Research, Lawrence Li',
    'discoveryYear': 2004,
    'etymology': 'latin',
    'meaning': 'moscow',
    'electronegativity': undefined,
    'firstCrystalStructure': undefined,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 400,
    'boiling': 1100,
    'density': 13.5,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 116,
    'symbol': 'Lv',
    'name': 'Livermorium',
    'mass': 293,
    'category': 4,
    'discover': 'Joint Institute for Nuclear Research and Lawrence',
    'discoveryYear': 2000,
    'etymology': 'english',
    'meaning': 'After Lawrence Livermore National Laboratory',
    'electronegativity': undefined,
    'firstCrystalStructure': undefined,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 435.5,
    'boiling': 812,
    'density': 12.9,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 117,
    'symbol': 'Ts',
    'name': 'Tennessine',
    'mass': 294,
    'category': 7,
    'discover': 'unknown',
    'discoveryYear': 'N/A',
    'etymology': 'cherokee via English',
    'meaning': 'location discovered',
    'electronegativity': undefined,
    'firstCrystalStructure': undefined,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': 450,
    'boiling': 610,
    'density': 7.2,
    radioactive: true,
    parentheses: true,
    'flame': ''
  },
  {
    'id': 118,
    'symbol': 'Og',
    'name': 'Oganesson',
    'mass': 294,
    'category': 8,
    'discover': 'unknown',
    'discoveryYear': 1999,
    'etymology': 'russian',
    'meaning': 'named after Yuri Oganessian',
    'electronegativity': undefined,
    'firstCrystalStructure': ECrystalStructure.CU_FACE,
    'secondCrystalStructure': undefined,
    'phase': EPhase.SOLID,
    'melting': undefined,
    'boiling': 80,
    'density': 5,
    radioactive: true,
    parentheses: true,
    'flame': ''
  }
];