import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import { reportWebVitals } from './reportWebVitals';
import { TableView, ElementView } from './Views';
import './styles.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path={'/'}>
          <Route index element={<TableView />} />
          <Route path={'category/:id'} element={<TableView />} />
          <Route path={'phase/:id'} element={<TableView />} />
          <Route path={'element/:id'} element={<ElementView />} />
          <Route path={'*'} element={<Navigate to={'/'} replace />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
