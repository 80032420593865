import React from 'react';
import {ReactComponent as Solid} from '../../../Assets/img/svg/solid.svg';
import {ReactComponent as Gas} from '../../../Assets/img/svg/gas.svg';
import {ReactComponent as Liquid} from '../../../Assets/img/svg/liquid.svg';
import { EPhase } from '../../../Interfaces';

interface IPhaseIcon {
    phase: EPhase;
}
export const PhaseIcon: React.FC<IPhaseIcon> = (props) => {
    if (props.phase === EPhase.GAS) {
        return <Gas className={'phaseIcon'} />;
    } else if (props.phase === EPhase.LIQUID) {
        return <Liquid className={'phaseIcon'} />;
    } else {
        return <Solid className={'phaseIcon'} />;
    }
};
