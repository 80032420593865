import React, { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import { SearchInput, SearchResult } from '.';
import { IElement } from '../../Interfaces';
import { searchFilter, useOutsideClick } from '../../Utils';
import './styles.scss';

/**
 * ## ISuggests
 *
 * * Search result should follow this interface.
 * * key and title is a minimum requirement for compatibility.
 *
 * @param key Suggested result element key value
 * @param title Title value of suggested result
 * @param description Description value of suggested result
 * @param tags Tags for suggested result
 */
export interface ISuggests {
	key: string;
	title: string;
	description?: string;
	tags?: string[];
}

/**
 * ## ISearchInputProps
 * @param placeholder Input placeholder string
 * @param onResultClick onClick action handler for every result
 * @param onChange onChange action listener for search input
 * @param onSubmit When the user clicks the icon or presses the enter key, the form is submitted (alternative to onResultClick when multiple results are available)
 * @param suggests Result query array
 * @param mobile Set mobile search input
 * @param className modify already set stylesheet
 * @param multiple Whether the select is multiple selections
 * @param allowFreeText Allow the user to enter a value that is not in the list of options
 */
export interface ISearchInputProps {
	placeholder?: string;
	onResultClick: (id: number) => void;
	onChange: (inputText: string) => void;
	onSubmit?: (inputText: string) => void;
	elements?: IElement[];
	mobile?: boolean;
	className?: string | React.ReactNode;
}

/**
 * ## Search
 * @param props see {@link ISearchInputProps}
 * @returns a search component
 */
export const Search: React.FC<ISearchInputProps> = (props) => {
	const [getShowState, setShowState] = useState(false);
	const [getShowSearch, setShowSearch] = useState<boolean>(false);
	const [getInputState, setInputState] = useState<string>('');
	
	const inputRefSingle: RefObject<HTMLInputElement> = useRef(null);
	const searchResultRef: RefObject<HTMLDivElement> = useRef(null);
	const clickRef = useRef<HTMLDivElement>(null);

	/**
	 * ## useOutsideClick
	 * Listen for clicks outside input field
	 */
	useOutsideClick(clickRef, () => {
		setShowState(false);
	});

	/**
	 * ## handleClick
	 * Handle click event, call @param props.onResultClick on corresponding element
	 * @param key the key string, representing element from search result
	 */
	const handleClick = (id: number) => {
		setShowState(false);
		setInputState('');
		props.onResultClick(id);
	};

	/**
	 * ## handleIconClick
	 * Icon click listener
	 * Called every time user clicks on icon
	 */
	const handleIconClick = () => {
		setInputState('');

		if (props.mobile) {
			setShowSearch(!getShowSearch);
			setShowState(!getShowState);
			setInputState('');
		} else {
			props.onSubmit?.(getInputState);
		}
	};

	/**
	 * Change focus to search results on certain keyboard events
	 * @param event
	 */
	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'ArrowDown' || event.key === 'Enter') {
			searchResultRef.current?.focus();
		}
	};

	/**
	 * ## handleOnFocus
	 * Action handler for input field focus
	 */
	const handleOnFocus = () => {
		inputRefSingle.current?.select();
		setShowState(true);
	};

	/**
	 * ## handleOnSubmit
	 * Handle submit event, call @param props.onSubmit with input value
	 */
	const handleSubmit = () => {
		props.onSubmit?.(getInputState);
	};

	const handleOnChangeSingle = (e: ChangeEvent<HTMLInputElement>) => {		
		setInputState(e.target.value);
		props.onChange(e.target.value);
	};

	/**
	 * Input element for a "normal" or single search. Write some text and get suggestions
	 */
	const inputElementSingle: JSX.Element = (
		<input
			disabled={props.mobile ? (getShowSearch ? false : true) : undefined}
			key={props.mobile ? 'inputSearch' : 'input_search_mobile'}
			ref={inputRefSingle}
			value={getInputState}
			onFocus={handleOnFocus}
			className={'input'}
			placeholder={props.placeholder}
			onKeyDown={handleKeyDown}
			onChange={handleOnChangeSingle}
		/>
	);

	return props.mobile ? (
		<SearchInput.Mobile
			className={props.className}
			clickRef={clickRef}
			input={inputElementSingle}
			showSearch={getShowSearch}
			onIconClick={handleIconClick}
			onSearchIconClick={handleSubmit}>
			
				<SearchResult
					ref={searchResultRef}
					key={'search_result_mobile'}
					elements={props.elements}
					getShowState={getShowState}
					onClick={handleClick}
					mobile={props.mobile}
				/>
			
		</SearchInput.Mobile>
	) : (
		<SearchInput.Desktop
			className={props.className}
			clickRef={clickRef}
			input={inputElementSingle}
			onIconClick={handleIconClick}
			onSearchIconClick={handleSubmit}>

				<SearchResult
					ref={searchResultRef}
					key={'search_result'}
					elements={props.elements}
					getShowState={getShowState}
					onClick={handleClick}
					mobile={props.mobile}
				/>

		</SearchInput.Desktop>
	);
};
