import React from 'react';
import { IElement } from '../../Interfaces';
import { Element } from '..';
import './styles.scss';
import { elements } from '../../Assets/Data';

interface ITable {
    data: IElement[]
}

export const Table: React.FC<ITable> = (props) => {
    return (
        <div className={'table'}>
                {/*********** ROW 0 ***********/}
                {renderEmpty()}
                <Group number={1} key={'group-1'} />
                {renderEmpty(16)}
                <Group number={8} key={'group-8'} />

                {/*********** ROW 1 ***********/}
                <Period number={1} key={'period-1'} />
                {renderElements(0,1)}
                <Group number={2} key={'group-2'} />
                {renderEmpty(10)}
                {renderGroups(3,7)}
                {renderElements(1,2)}

                {/*********** ROW 2 ***********/}
                <Period number={2} key={'period-2'} />
                {renderElements(2,4)}
                {renderEmpty(10)}
                {renderElements(4,10)}

                {/*********** ROW 3 ***********/}
                <Period number={3} key={'period-3'} />
                {renderElements(10,12)}
                {renderGroups(1,10)}
                {renderElements(12,18)}

                {/*********** ROW 4 ***********/}
                <Period number={4} key={'period-4'} />
                {renderElements(18,36)}

                {/*********** ROW 5 ***********/}
                <Period number={5} key={'period-5'} />
                {renderElements(36,54)}

                {/*********** ROW 6 ***********/}
                <Period number={6} key={'period-6'} />
                {renderElements(54,56)}
                <div className={'elementContainer'} key={'empty-elements-57-71'}>
                    <p className={'id'}>{'57-71'}</p>
                </div>
                {renderElements(71,86)}

                {/*********** ROW 7 ***********/}
                <Period number={7} key={'period-7'} />
                {renderElements(86,88)}
                <div className={'elementContainer'} key={'empty-elements-89-103'}>
                    <p className={'id'}>{'89-103'}</p>
                </div>
                {renderElements(103, 119)}

                {/*********** EMPTY ROW ***********/}
                {renderEmpty(18)}
                <div className={'elementContainer spacer'} key={'spacer-row'}></div>

                {/*********** ROW 8 ***********/}
                {renderEmpty(4)}
                {renderElements(56,71)}

                {/*********** ROW 9 ***********/}
                {renderEmpty(4)}
                {renderElements(88,103)}
        </div>
    );
};

interface IPeriodGroup {
    number: number;
}
const Period: React.FC<IPeriodGroup> = (props) => {
    return (
        <div className={'periodNumber'}>
            <p>{props.number}</p>
        </div>
    ); 
};

const Group: React.FC<IPeriodGroup> = (props) => {
    return (
        <div className={'groupNumber'}>
            <p>{props.number}</p>
        </div>
    ); 
};

const renderGroups = (start: number, end: number) => {
    const groups = [];
    for (let i=start; i<end+1; i++) {
        groups.push(<Group number={i} key={`group-loop-${i}`} />);
    }
    return groups;
};

let countEmptyRendered = 0;
const renderEmpty = (count?: number) => {
    const countEmpty = count ? count : 1;
    const array = [];
    for (let i=0; i<countEmpty; i++) {
        array.push(<div className={'empty'} key={`empty-${countEmptyRendered}`}></div>);
        countEmptyRendered++;
    }
    return array;
};

const renderElements = (start: number, end: number) => {
    // Get all the elements from the original array
    const selectedElements = elements.slice(start, end);
    // Return all elements rendered as Element
    return selectedElements.map((element) => <Element {...element} key={`table-element-${element.id}`} />);
};
