
export enum ECrystalStructure {
    CUBIC = 'cu_body',
    HEXA = 'hexa',
    HEXA_CLOSE = 'hexa_close',
    DOUBLE_HEXA = 'hexa_double',
    CU_BODY = 'cu_body',
    CU_DIA = 'cu_dia',
    CU_FACE = 'cu_face',
    ORTHO = 'ortho',
    RHOMBO = 'rhombo',
    TETRA = 'tetra',
    MONO = 'mono'
}