import React, { FC, Ref } from 'react';
import './styles.scss';
import { Menu } from 'antd';
import { IElement } from '../../../Interfaces';
import { ColorBox } from '../..';
import { categories } from '../../../Assets/Data';

/**
 * ## ISearchItemProps
 * @param suggests The ISuggests array with query results
 * @param getShowState Set result show state
 * @param mobile set mobile display sheet
 * @param ref React ref for first element of the result list
 * @param onClick Item click action handler
 */
interface ISearchItemProps {
	elements?: IElement[];
	getShowState: boolean;
	mobile?: boolean;
	ref?: Ref<HTMLDivElement>;
	onClick: (id: number) => void;
}

/* eslint-disable react/forbid-component-props */
/**
 * ## SearchResult
 * @param props see {@link ISearchItemProps}
 * @returns A result component with suggested search results
 */
export const SearchResult: FC<ISearchItemProps> = React.forwardRef<HTMLDivElement, ISearchItemProps>((props, ref) => {
	return props.elements && props.elements.length > 0 && props.getShowState ? (
		<Menu className={`searchContent${props.mobile ? 'Mobile' : ''}`} tabIndex={-1}>
			{props.elements.map((item, index) => (
				<Menu.Item onClick={() => props.onClick(item.id)} key={`search_menu_item_btn_${index}`} className={'searchMenuItem'}>
					{/* The searchMenuItemContainer is the reference point for keyboard accessibility focus changes in parent component */}
					<div className={'searchMenuItemContainer'} ref={index === 0 ? ref : null} tabIndex={index+1}>
						<div className={'colorBoxContainer'}>
							<ColorBox size={'22px'} color={categories[item.category].color} style={{marginLeft: 'auto', marginRight: 'auto'}} />
						</div>
						<div className={'textContainer'}>
							<p className={'symbol'}>{item.symbol}</p>
							<p className={'name'}>{item.name}</p>
						</div>
					</div>
				</Menu.Item>
			))}
		</Menu>
	) : null;
});
/* eslint-enable react/forbid-component-props */
