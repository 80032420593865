import React from 'react';
import './styles.scss';

interface IColorBox {
    size: string;
    color: string;
    style?: React.CSSProperties;
}
/* eslint-disable react/forbid-dom-props */
export const ColorBox: React.FC<IColorBox> = (props) => (
    <div className={'colorBox'} style={{
        backgroundColor: props.color, 
        width: props.size, 
        height: props.size,
        ...props.style
    }} />
);
/* eslint-enable react/forbid-dom-props */
