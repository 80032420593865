import React from 'react';
import { elements } from '../../Assets/Data';
import { Header, Table } from '../../Components';
import './styles.scss';


export const TableView: React.FC = () => {

    return (
        <>
            <Header />
            <Table data={elements} />
        </>
    );
};
