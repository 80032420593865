import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { categories, elements } from '../../Assets/Data';
import { BigElement, ColorBox, CrystalIcon, Header, PhaseIcon, ShellIcon } from '../../Components';
import { ICategory, IElement } from '../../Interfaces';
import './styles.scss';

export const ElementView: React.FC = () => {
    const navigate = useNavigate();
    const param = useParams();
    const [getElement, setElement] = useState<IElement>();
    const [getCategory, setCategory] = useState<ICategory>(categories[0]);

    const regex = /^\d{1,3}$/;

    // If the route changes, then load the new element
    useEffect(() => {
        if (param.id && regex.test(param.id)) {
            const id = parseInt(param.id)-1;
            setElement(elements[id]);
        } else {
            navigate('/');
        }
    }, [param.id]);

    // Set category as state for easier access to especially color
    useEffect(() => {
        if (getElement) {
            setCategory(categories[getElement.category]);
        }
    }, [getElement]);
    
    
    /* eslint-disable react/forbid-dom-props */
    if (getElement) {
        return (
            <>
                <Header />
                <div className={'elementView'}>                
                    <div className={'column'} >
                        <BigElement {...getElement} />
                        <Link className={'noStyle'} to={`/category/${getElement.category}`}>
                            <Details icon={<ColorBox size={'36px'} color={getCategory.color} />} value={getCategory.name} />
                        </Link>
                        <Link className={'noStyle'} to={`/phase/${getElement.phase.toLowerCase()}`}>
                            <Details icon={<PhaseIcon phase={getElement.phase} />} value={getElement.phase} />
                        </Link>
                        
                        <Details title={'Discovery'} value={`${getElement.discover} ${getElement.discoveryYear ? `(${getElement.discoveryYear})` : ''}`} />
                        <Details title={'Etymology'} value={getElement.etymology} />
                        <Details title={'Meaning'} value={getElement.meaning} />
                    </div>
                    <div className={'column'} >
                        <ColorHeader text={'Electron configuration'} color={getCategory.color} />
                        <ShellIcon id={getElement.id} />

                        <ColorHeader text={'Crystal structure'} color={getCategory.color} />
                        <CrystalIcon name={getElement.firstCrystalStructure} />
                        <CrystalIcon name={getElement.secondCrystalStructure} />
                    </div>
                    <div className={'column'} >
                        <ColorHeader text={'Physical properties'} color={getCategory.color} />
                        <Details title={'Phase'} titleSmall={'at 21 \u00b0C and 101.325 kPa'} value={getElement.phase} />
                        <Details title={'Melting point'} titleSmall={'at 101.325 kPa'} value={`${getElement.melting} \u00b0C`} />
                        <Details title={'Boiling point'} titleSmall={'at 101.325 kPa'} value={`${getElement.boiling} \u00b0C`} />
                        <Details title={'Density'} titleSmall={'at 21 \u00b0C and 101.325 kPa'} value={`${getElement.density} g/cm\u00B3`} />
                        <Details title={'Electronegativity'} value={getElement.electronegativity} />
                    </div>
                </div>
            </>
        );
    } else {
        return (<></>);
    }
    /* eslint-enable react/forbid-dom-props */
};


interface IDetails {
    icon?: JSX.Element;
    title?: string;
    titleSmall?: string
    value?: string|number;
    color?: string;
}

/**
 * Render a row of details about an element. With an icon or with a title and value
 * @param props @see IDetails
 * @returns React.FC
 */
const Details: React.FC<IDetails> = (props) => {
    if (props.icon && props.value) {
        return (
            <div className={'elementDetailsContainer iconWithText'}>
                <div className={'colorBoxContainer'}>
                    {props.icon}
                </div>
                <div className={'col'}>
                    <p className={'elementDetails'}>{props.value}</p>
                </div>
            </div>
        );
    } else if (props.title && props.value) {
        return (
            <div className={'elementDetailsContainer'}>
                <h4 className={'elementProperty'}>{props.title} <span className={'titleSmall'}>{props.titleSmall ? `(${props.titleSmall})` : ''}</span></h4>
                <p className={'elementDetails'}>{props.value}</p>
            </div>
        );
    } else {
        return <></>;
    }
};


interface IColorHeader {
    color?: string;
    text?: string;
}
/**
 * Render a header with a specific color (given by the element category)
 * @param props @see IColorHeader
 * @returns <h2> element
 */
const ColorHeader: React.FC<IColorHeader> = (props) => {
    if (props.color && props.text) {
        /* eslint-disable react/forbid-dom-props */
        return <h2 style={{color: props.color}}>{props.text}</h2>;
        /* eslint-enable react/forbid-dom-props */
    } else {
        return <></>;
    }
};
