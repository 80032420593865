import { elements } from '../Assets/Data';
import { IElement } from '../Interfaces';

export const searchFilter = (search?: string): IElement[] => {
    // Filter and return only elements including the search term
    let results: IElement[] = [];

    // Only filter if the search term is not empty
    if (search) {
        const searchLowerCase = search.toLowerCase();
        
        // Now filter the elements based on the search term
        let resultsDuplicates: IElement[] = [];
        resultsDuplicates = elements.filter(filters.id(searchLowerCase));
        resultsDuplicates = resultsDuplicates.concat(elements.filter(filters.symbol(searchLowerCase)));
        resultsDuplicates = resultsDuplicates.concat(elements.filter(filters.name(searchLowerCase)));
        
        // The results might contain duplicates, so remove them
        const seen = new Set();
        results = resultsDuplicates.filter(item => {
            const duplicate = seen.has(item.id);
            seen.add(item.id);
            return !duplicate;
        });
    }

    return results;
};

// First check for the element id
const searchFilterId = (search: string) => (element: IElement) => {
    return element.id.toString().startsWith(search);
};

// Then check for the element symbol
const searchFilterSymbol = (search: string) => (element: IElement) => {
    return element.symbol.toLowerCase().startsWith(search);
};

// Lastly check for the element name
const searchFilterName = (search: string) => (element: IElement) => {
    return element.name.toLowerCase().includes(search);
};

// All filters collected in one function
const filters = {
    id: searchFilterId,
    symbol: searchFilterSymbol,
    name: searchFilterName
};