
import React from 'react';
import { categories } from '../../Assets/Data';
import { IElement } from '../../Interfaces';
import { ReactComponent as Radioactive } from '../../Assets/img/svg/radioactive.svg';
import './styles.scss';


export const BigElement: React.FC<IElement> = (props) => {

    /* eslint-disable react/forbid-component-props */
    /* eslint-disable react/forbid-dom-props */
    return (
        <div className={'bigElementContainer'}>
            <p className={'id'}>{props.id}</p>
            {/*<Radioactive className={'radioactive'} style={{display: props.radioactive ? 'block' : 'block'}} />*/}
            <p className={'symbol'} style={{color: categories[props.category].color}}>{props.symbol}</p>
            <p className={'name'}>{props.name}</p>
            <p className={'mass'}>{props.mass}</p>
        </div>
    );
};
/* eslint-enable react/forbid-dom-props */
/* eslint-enable react/forbid-component-props */